import React from 'react';
import dynamic from 'next/dynamic';
import router from 'next/router';
import LoadingIndicator from '../category/common/LoadingIndicator';
import { inject, observer } from 'mobx-react';

const Login = dynamic(() => import('../auth/Login'), {
  ssr: false,
  loading: () => <LoadingIndicator />
});

@inject('userStore', 'uiStore')
@observer
class login extends React.Component {
  static async getInitialProps(props) {
    return props.query;
  }

  render() {
    return <Login />;
  }
}

export default login;
